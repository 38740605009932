import React from 'react';
import FinsWashIcon from '../components/icons/finswash';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { useMedia } from '../utils/hooks';

import items from '../utils/nav-items';
import '../styles/promo-page.css';

const onNavItemSelected = (index: number) => {
  history.pushState({}, 'FinsWash', `/#${items[index].id}`);
  window.location.reload();
};

const TermsPage = () => {
  const iconSize = useMedia(
    ['(min-width: 376px)', '(min-width: 275px)', '(min-width: 0px)'],
    [
      { width: '206', height: '40' },
      { width: '249.18', height: '47.54' },
      { width: '166.12', height: '31.69' }
    ],
    { width: '206', height: '40' }
  );

  return (
    <div
      id="TermsNPolicy"
      className="Promo-container"
      style={{ color: 'black', margin: '64px 32px' }}
    >
      <SEO title="Fins Wash Terms and Conditions" />

      <div className="Promo-container__header" style={{ marginBottom: 32 }}>
        <FinsWashIcon color="#16478E" {...iconSize} />
      </div>
      <h1>Terms and Conditions</h1>
      <p>
        <strong>
          Please Read These Terms and Conditions of Use Carefully Before
          Downloading, Accessing, or Using any FINS Car Wash Mobile Application
        </strong>
        <br />
        <br />
        The Terms and Conditions contained here are applicable to your access
        to, and use of the FINS Car Wash Mobile Application. These Terms and
        Conditions of Use are a legal and binding agreement between you and FINS
        Car Wash Mobile Application, its affiliates, and related entities.
        context may require, as “we”, “us”, or “our”). These Terms of Use govern
        your use of the FINS Car Wash Mobile Application, which may include
        loyalty programs, offers/rewards, content, information, services, and
        features made available through the FINS Car Wash Mobile Application. If
        you do not agree to these Terms and Conditions of Use, then you should
        not use FINS Car Wash Mobile Application
      </p>
      <p style={{ fontWeight: 'bold' }}>
        Your use of the FINS Car Wash Mobile Application is at your risk. If you
        are dissatisfied with the FINS Car Wash Mobile Application in any way,
        its programs, offers/rewards, contents, features, or these Terms and
        Conditions of Use, your sole and exclusive remedy is to discontinue
        accessing and using the FINS Car Wash Mobile Application.
      </p>
      <p>
        <strong>Modifications to These Terms and Conditions of Use</strong>
        <br />
        <br />
        At any time we reserve the right to change, modify, update or revise
        these Terms and Conditions. Any changes will become effective upon
        posting to the FINS Car Wash Mobile Application, along with the date on
        which it was most recently updated as indicated by the “Last Updated”
        section at the end of these Terms and Conditions of Use. Your continued
        access to or use of the FINS Car Wash Mobile Application and its
        programs and offers/rewards after any such changes indicates your
        acceptance of the Terms and Conditions of Use as modified. It is your
        responsibility to review the Terms and Conditions of Use regularly for
        updates.
      </p>
      <p>
        <strong>FINS Car Wash Mobile Application</strong>
        <br />
        <br />
        FINS Car Wash Mobile Application and all content, information, and other
        materials featured, displayed, contained, and available on the FINS Car
        Wash Mobile Application, including, but not limited to, all text,
        images, graphics, designs, illustrations, photographs, pictures, audio,
        software, and video clips, the “look and feel”, pages, screens, content
        arrangement, and computer programs (collectively, “Materials”) are owned
        by or licensed to FINS Car Wash Mobile Application and its Affiliates
        and are protected by copyright, trademark, trade dress, patent, and/or
        other intellectual property rights and unfair competition laws under the
        United States and foreign laws. Subject to your compliance with these
        Terms and Conditions of Use, we grant you a personal, non-exclusive,
        non-transferable, limited right to access, use, display, and download
        the FINS Car Wash Mobile Application Services for noncommercial purposes
        only. You may view, display, copy, download, and print the Materials
        solely for your own personal, noncommercial use. All copyright,
        trademark, and other proprietary notices contained on the Materials must
        be retained on all copies thereof. You may not, in any way, otherwise
        copy, reproduce, distribute, transmit, display, perform, reproduce,
        publish, license, modify, create derivative works from, sell, or
        exploit, in whole or in part, the FINS Car Wash Mobile Application or
        its Materials. Except as expressly provided herein, we do not grant any
        express or implied right to you under any patents, trademarks,
        copyrights, or trade secret information. You agree to abide by all
        additional terms, conditions, and restrictions included within the FINS
        Car Wash Mobile Application, the Materials, and/or the programs,
        features, and services. FINS Car Wash may at any time, for any reason,
        and without notice or liability: (1) modify, suspend, or terminate
        operation of or access to any FINS Online Service, or any portion
        thereof; (2) change, revise, or modify the FINS Car Wash Mobile
        Application, the Materials, or any portion thereof; (3) interrupt the
        operation of the FINS Car Wash Mobile Application, or any portion
        thereof, as necessary to perform routine or non-routine maintenance,
        error correction, or for any other purposes; (4) impose limits on
        certain programs, feature s, and services, or restrict your access to
        the FINS Car Wash Mobile Application, in whole or in part; and/or (5)
        terminate the authorization, rights, and license given above, upon which
        you shall immediately destroy all Materials.
      </p>
      <p>
        <strong>User Conduct</strong>
        <br />
        <br />
        You agree to use the FINS Car Wash Mobile Application, the Materials,
        and the programs, features and services in accordance with these Terms
        and Conditions of Use and all applicable laws and regulations. You agree
        not to:
        <br />
        • modify, adapt, translate, or reverse engineer any portion of the FINS
        Car Wash Mobile Application or the Materials;
        <br />
        • use the FINS Car Wash Mobile Application or any Materials, features,
        or services for any unlawful, fraudulent, or malicious purposes, or to
        solicit any such activity;
        <br />
        • attempt to gain unauthorized access to any accounts, features,
        systems, or networks through hacking, password mining, or any other
        means;
        <br />
        • use the FINS Car Wash Mobile Application or any feature in any way
        that could disrupt, damage, disable, overburden, or impair it or its
        systems, servers, or networks;
        <br />
        • frame or mirror any portion or feature of the FINS Car Wash Mobile
        Application;
        <br />
        • use the FINS Car Wash Mobile Application or any feature for
        advertisements, chain letters, spamming, junk mail, solicitations, or
        any other commercial purposes;
        <br />
        • use any “deep-link”, “page-scrape”, “robot”, “spider”, or other
        automatic device, program, algorithm, or methodology, or any similar or
        equivalent manual process, to: (1) access, acquire, copy, or monitor any
        portion of the FINS Car Wash Mobile Application or the Materials; (2)
        reproduce or circumvent the navigational structure or presentation of
        the FINS Car Wash Mobile Application or the Materials; (3) obtain or
        attempt to obtain any materials, documents, or information through any
        means not purposely made available through the FINS Car Wash Mobile
        Application;
        <br />
        • use any device, software, or routine to interfere or attempt to
        interfere with the proper working of the FINS Car Wash Mobile
        Application or with any other person’s use or enjoyment of the FINS Car
        Wash Mobile Application;
        <br />
        • transmit any viruses, worms, defects, Trojan horses, time bombs,
        cancel-bots, corrupted files, or other items of a destructive nature;
        <br />
        • circumvent or attempt to circumvent any security or authentication
        measures implemented by or on behalf of FINS Car Wash;
        <br />
        • forge headers or otherwise manipulate identifiers;
        <br />
        • post, transmit, submit, or include any unlawful, harmful, threatening,
        abusive, harassing, defamatory, vulgar, obscene, sexually explicit,
        profane, hateful, racially, ethnically, or otherwise objectionable
        content, material, or information of any kind, or any content, material,
        or information that may give rise to criminal or civil liability;
        <br />
        • submit or post any false or misleading information; and/or
        <br />
        • violate, infringe, or misappropriate the intellectual property,
        publicity, privacy, or other proprietary rights of FINS Car Wash, its
        Affiliates, or any other person or entity.
        <br />
        <br />
        We reserve the right to prohibit access, use, conduct, communications,
        or content that we, in our sole discretion, deem to be harmful to the
        FINS Car Wash Mobile Application, the Materials, users, us, our brand,
        or any other person or entity, or that violates these Terms and
        Conditions of Use and/or applicable law.
      </p>
      <p>
        <strong>Submissions</strong>
        <br />
        <br />
        Except for any personally identifiable information we may collect from
        you under the guidelines established in our Privacy Policy, any
        materials, remarks, suggestions, ideas, photos, stories and/or other
        information you communicate or submit to us through FINS Car Wash Mobile
        Application, via phone, or otherwise (“Submissions”) will be considered
        non-confidential and non-proprietary. By providing a Submission to us,
        you expressly grant us an unrestricted, irrevocable, perpetual,
        transferable (i.e., fully assignable and sub-licensable), worldwide,
        royalty-free license to disclose, copy, reproduce, display, publicly
        perform, transmit, distribute, translate, reformat, incorporate, and
        otherwise use your Submission along with your name, photograph, voice,
        likeness and other information, content, or materials embodied therein,
        in whole or in part, and create derivative works therefrom, in any media
        now known or hereafter developed, and for any and all commercial or
        non-commercial purposes without compensation to you or any else. You
        also permit any user to access, display, view, store, and reproduce, for
        personal use only, your Submission as posted by us. <br />
        <br />
        We may, but shall not be obligated to, in our sole discretion, post any
        Submission on the FINS Car Wash Mobile Application and identify you as
        the submitting party. We may, without notice to you, refuse or edit
        Submissions for any reason or no reason, including those Submissions
        that violate these Terms and Conditions of Use, are irrelevant or
        inappropriate, or represent us in a negative way or in any manner not
        consistent with our brand and/or reputation, as determined by us in our
        sole discretion. You agree that any statements that you make about us or
        any of our products, services, or programs in any Submission that you
        make accurately reflect your personal beliefs and experiences with us
        and our products and services, and that any opinions submitted are true
        to the best of your knowledge. <br />
        <br />
        You are fully responsible for your Submissions. You agree that your
        Submissions comply in all respects with these Terms and Conditions of
        Use and shall not be made on behalf of any person or entity without
        their prior written consent. You may not include any content (e.g.,
        text, photos, images, quotes, logos, etc.) that is the property of
        another (e.g., protected by copyright, trademark, or other intellectual
        property rights), unless you have express, written permission to do so.
      </p>
      <p>
        <strong>Programs and Promotions</strong>
        <br />
        <br />
        Any programs, offers/rewards, sweepstakes, contests, raffles, surveys or
        other similar promotions (collectively, “Promotions”) made available
        through the FINS Car Wash Mobile Application may be governed by rules
        and/or terms that are additional to these Terms and Conditions of Use.
        By participating in any such Promotion, you will become subject to any
        such additional Promotions terms. FINS Car Wash urges you to read the
        applicable Promotions terms, which are either included herein or linked
        from the particular Promotion, and to review our Privacy Policy which,
        in addition to these Terms and Conditions of Use, governs any
        information you submit in connection with any such Promotions.
      </p>
      <p>
        <strong>FINS Car Wash Mobile App</strong>
        <br />
        <br />
        In order to use FINS Car Wash Mobile Application made available through
        the FINS Car Wash Mobile Application (“the App”) you must have a
        compatible mobile device; FINS Car Wash does not warrant that the App
        will be compatible with your mobile device. <br />
        <br />
        If you access the App using an Apple iOS or Android powered device,
        Apple Inc. or Google, Inc., and their subsidiaries, respectively, shall
        be a third-party beneficiary to this contract and you agree that these
        third-party beneficiaries have the right to enforce these Terms of Use
        against you. However, these third-party beneficiaries are not a party to
        these Terms and Conditions of Use and are not responsible for the
        provision or support of the App. You agree that your access to the
        Mobile App also shall be subject to the usage terms set forth in the
        applicable third-party beneficiary’s terms of service. <br />
        <br />
        The App may automatically download and install updates from time to time
        or require the downloading and installation of updates or new versions
        for continued use. You acknowledge that in some instances updates and/or
        new versions may reduce or remove features and functionality in prior
        versions of the App.
      </p>
      <p>
        <strong>FINS Car Wash Digital Gift Card</strong>
        <br />
        <br />
        The FINS Car Wash Mobile App (the “App”) allows you to load funds onto a
        FINS Car Wash Digital Gift Card for use at participating FINS Car Wash
        locations. To load funds to your FINS Car Wash Digital Gift Card, sign
        into your FINS Car Wash Account on the App, then open your card and
        enter a dollar amount and payment method (credit card, Apply Pay, etc.).
        The dollar value that you load onto your FINS Car Wash Digital Gift Card
        with the FINS Car Wash app is a prepayment redeemable solely for the
        goods and services of participating stores. We offer the FINS Car Wash
        Digital Gift Card to make it more convenient for you to shop with us. We
        have no liability for any temporary inability to access funds loaded to
        a FINS Car Wash Digital Gift Card for any reason, including but not
        limited to connectivity failures, problems with your mobile phone or
        other device, temporary issues with our service providers, or any other
        reason. Unless otherwise required by law or permitted by these Terms and
        Conditions, the dollar value on your FINS Car Wash Digital Gift Card is
        nonrefundable and may not be redeemed for cash nor via a credit back to
        a credit card, including the credit card from which such funds were
        originally loaded. However, upon request, unused balances on a Digital
        Gift Card accessible via the App may be transferred to a physical FINS
        Car Wash gift card that you may use to make future purchases of FINS Car
        Wash goods and services or provide to a de of your choice.
      </p>
      <p>
        <strong>FINS Car Wash Offers/Rewards Program</strong>
        <br />
        <br />
        The FINS Car Wash Offers/rewards program is operated by FINS Car Wash
        and subject to the following additional terms and conditions (the
        “Offers/rewards Terms”).
        <br />
        <br />
        <strong>Eligibility.</strong> FINS Car Wash Offers/rewards
        (“Offers/rewards”) are limited to persons residing in the United States
        who are eligible to use the FINS Car Wash Mobile Application and who
        download the FINS Car Wash Mobile App (the “App”) and sign-up for a FINS
        Car Wash account. You must be at least 13 years of age to redeem
        Offers/rewards in the FINS Car Wash Offers/rewards program. Some
        activities may only be available to those who are at least 18 years or
        age. FINS Car Wash accounts are limited to one individual per account.
        Offers/rewards are valid only at participating FINS Car Washes. To
        signup for a FINS Car Wash account, download the App to your AndroidTM
        or iPhone® device and follow the prompts to access account sign-up
        and/or sign-in page in the App. You can also sign up by following the
        prompts at www.finswash.com or by creating an online account. (Note: In
        order to receive FINS Car Wash Offers/rewards, you must download the app
        and redeem using the Bar Code included in the app. See below for
        details) You will be required to provide the requested information,
        including, but not limited to, user name, password, name, and e-mail
        address. FINS Car Wash may periodically request additional information
        to help us get to know you better. You agree to provide true and
        accurate information and to ensure that information provided by you is
        up-to-date and accurate. Communications with you will be made using
        contact information most recently provided by you. FINS Car Wash is not
        responsible for your inability to receive or redeem Offers/rewards or
        other loss arising from your failure to provide and maintain accurate
        contact information. FINS Car Wash may send messages from time to time
        using the contact information provided by you. Such messages may include
        system updates, flash bulletins, Offer confirmations, administrative
        announcements, special requests and the like and may be sent via
        notifications in the App, via e-mail, regular mail, or communicated to
        you on a receipt or display at the time of use of an your account.
        <br />
        <br />
        Your participation in any Offer is personal and non-transferable. Offer
        status and/or individual Offer may not be purchased, sold, bartered,
        brokered or otherwise transferred. Offers/rewards made via FINS Car Wash
        are purely promotional offers/rewards. You agree that no portion of your
        payment for purchases qualifying for any Offer constitutes consideration
        paid for any Offer.
        <br />
        <br />
        <strong>Offers/rewards.</strong> The FINS Offers/rewards are issued on a
        periodic basis. Upon signing up, you will receive notice via the FINS
        Car Wash account of your current Offer. Once you activate a FINS Car
        Wash account, you are eligible to receive special offers/rewards from
        time to time. The number, type and frequency of Offers/rewards are based
        on a number of factors, each of which are subject to change, without
        notice. Those factors may include, without limitation, volume and type
        of purchases at participating FINS Car Wash locations, number of visits
        and frequency of visits to participating FINS Car Wash locations,
        interaction with other FINS Car Wash applications or promotions; or
        special offers/rewards made directly by FINS Car Wash Operators or FINS
        Car Wash which may include ways to progress toward your next
        reward/offer via a special promotion. For Offers/rewards that are based
        on purchases, the following types of purchases at a participating FINS
        Car Wash qualify: (1) placing and completing a Mobile order for a
        qualifying purchase through the App; (2) using your FINS Car Wash
        Digital Gift Card to complete a purchase or transaction at a
        participating FINS Car Wash ; (3) logging into your FINS Car Wash
        account online and making an online purchase at a participating FINS Car
        Wash ; and (4) or completing a transaction at a participating FINS Car
        Wash and scanning the digital code linking to your FINS Car Wash account
        at checkout.
        <br />
        <br />
        You must use the App or if placing an online order, be signed in to your
        FINS Car Wash account, to complete the qualifying purchase or activity
        in order for that purchase or activity to count towards your progress to
        the next qualifying Offer. Benefits applied from any single qualifying
        purchase or activity may only be credited to (1) FINS Car Wash account.
        The qualifying purchase or activity will be applied to your account
        after completion of the qualifying purchase or activity. In certain
        cases, it may take twenty-four (24) hours or more to be credited. FINS
        Car Wash shall not be responsible for, or liable to, you, or any person
        or entity, in any way for any losses, costs or expenses incurred by a
        delay, error or omission in crediting a qualifying purchase or activity
        to your FINS Car Wash account.
        <br />
        <br />
        Offers/rewards are purely promotional offers/rewards and may have
        certain restrictions including expiration dates and short, time-limited
        redemption periods. All Offers/rewards must be redeemed prior to the
        stated expiration date for the Offer and in compliance with any other
        conditions associated with the particular Offer to be valid. It is your
        responsibility to check your FINS Car Wash account periodically for
        Offers/rewards and use the Offers/rewards prior to the stated expiration
        date.
        <br />
        <br />
        Activities which allow progress towards Offers/rewards do not count
        towards and may not be combined with other programs. Activities tied to
        specific Offers/rewards must be fully completed and will only result in
        one Offer, regardless of the number of times the activity is performed.
        FINS Car Wash reserves the right to change, modify or update these
        Offers/rewards and the qualifying purchases and activities to progress
        to an Offer from time to time without notice. Certain purchases and
        transactions do not qualify, including, without limitation: (i)
        purchases of FINS Car Wash gift cards or gift certificates; (ii)
        purchases or transactions made using an Offer or other promotional item;
        or (iii) purchases made as a guest (e.g. made while not signed in to
        your FINS Car Wash account), whether made in-store, online or through
        the App. FINS Car Wash is under no obligation to provide you with any
        particular number of Offer opportunities or special offers/rewards. FINS
        Car Wash reserves the right to limit enrollment at any time.
        <strong>Special Offers/rewards:</strong> FINS Car Wash and participating
        FINS Car Wash, in their sole and absolute discretion, may periodically
        offer the opportunity for additional Offers/rewards on particular
        transactions or special offers/rewards (“Special Offers/rewards”).
        Special Offers/rewards may also be distributed to you via the App or via
        e-mail or mail from time to time (based on the information you have in
        your account). Special Offers/rewards may include periodic promotional
        offers/rewards on washes and/or services. You must have your App set to
        receive notifications and/or have opted-in to receive email
        communications in order to receive promotional and marketing
        offers/rewards. Special Offers/rewards may be customized based on your
        qualifying purchases and preferences. In certain events, Special
        Offers/rewards may require that you have location settings enabled for
        them to be distributed to your App. Please note that FINS Car Wash and
        participating FINS Car Washes may be unable to send these Special
        Offers/rewards to you if you have turned off your notifications or
        location settings in the App; if you have elected not to receive email
        communications from FINS Car Wash; or if you have poor network
        connectivity. Special Offers/rewards may have certain restrictions
        including expiration dates and short, time-limited redemption periods.
        Special Offers/rewards are personal to you and cannot be shared, copied
        or transferred. You may be required to present your FINS Car Wash
        account information at the participating FINS Car Wash in order to
        redeem Special Offers/rewards. Read each offer carefully for specific
        details, conditions, limitations and restrictions.
        <br />
        <br />
        <strong>Redeeming Offers/rewards.</strong> To redeem an Offer, you must
        present the valid Offer in the App at a participating FINS Car Wash (for
        in-person transactions) or be logged into your FINS Car Wash account
        (for in App transactions). Offers/rewards are subject to availability
        and limitations as may be imposed by FINS Car Wash. Determinations
        regarding redemption of Offers/rewards aresubject to the discretion of
        the participating FINS Car Wash. Only one Offer may be redeemed per
        transaction. Once redeemed, the Offer will be indicated as redeemed in
        your FINS Car Wash account. Updates to the status of Offers/rewards may
        take 24-48 hours or more to show up in your account. A list of current
        Offers/rewards and redeemed and expired Offers/rewards are available
        under your FINS Car Wash account. Offers/rewards have no cash value and
        are not redeemable for cash, gift certificates or gift cards.
        Offers/rewards are promotional and do not constitute property of any
        FINS Car Wash account holder. FINS Car Wash reserves the right to limit
        the redemption of specific Offers/rewards and limit the number of
        Offers/rewards available. FINS Car Wash reserves the right to modify the
        number of qualifying purchases or transactions needed to receive
        specific Offers/rewards.
        <br />
        <br />
        <strong>Cancellation; Termination.</strong> You may opt-out of FINS Car
        Wash Offers/rewards at any time, for any reason by cancelling your FINS
        Car Wash account. If you cancel your account, any unredeemed
        Offers/rewards and/or progress towards other Offers/rewards will
        automatically expire and will no longer be available for redemption. If
        you re-enroll at any time, you will start at the introductory
        Offers/rewards level as applicable.
        <br />
        <br />
        Upon cancellation, your FINS Car Wash account shall immediately cease
        and you may no longer receive Offers/rewards, redeem Offers/rewards
        previously received, receive FINS Car Wash benefits, or make purchases
        using remaining balances on Digital Gift Card funds accessible via your
        FINS Car Wash account. FINS Car Wash has no obligation to compensate you
        for any unredeemed Offers/rewards or benefits following cancellation, or
        for unused balances on Digital Gift Cards accessible via your FINS Car
        Wash account. FINS Car Wash will not refund any unused balances on
        Digital Gift Card funds accessible through your FINS Car Wash account
        (including via credit back to a credit card from which such funds may
        have been loaded to the Digital Gift Card), but will upon request at the
        time of cancellation of your FINS Car Wash account transfer any
        unredeemed Digital Gift Card balances accessible through your FINS Car
        Wash account to a physical FINS Car Wash gift card that you may use to
        make future purchases or gift to a designee of your choosing. Because we
        plan our communications in advance, it may take several weeks for your
        request to become effective. If you continue to receive our
        communications after expressing an opt-out preference, please let us
        know so that we can investigate the situation.
        <br />
        <br />
        FINS Car Wash reserves the right to suspend or terminate, at FINS Car
        Wash’s sole and absolute discretion, any FINS Car Wash account if FINS
        Car Wash believes that the account holder has abused FINS Car Wash
        Offers/rewards privileges, has violated or acted inconsistently with
        these FINS Car Wash Offers/rewards Terms and Conditions or applicable
        law or acted in a manner harmful to our interests. FINS Car Wash further
        reserves the right to terminate a FINS Car Wash account that has been
        inactive for a period of three (3) years or more. You understand and
        agree that termination by FINS Car Wash of a FINS Car Wash account will
        result in in the cancellation of all Offers/rewards associated with that
        account and the inability to earn and/or redeem further Offers/rewards,
        as well as inability to access or use any remaining balances on Digital
        Gift Cards accessible via your FINS Car Wash account. Upon termination
        of your FINS Car Wash account, FINS Car Wash will not refund any unused
        balances on Digital Gift Card funds accessible through your FINS Car
        Wash account (including via credit back to a credit card from which such
        funds may have been loaded to the Digital Gift Card), but will upon
        request at the time of termination of your FINS Car Wash account
        transfer any unredeemed Digital Gift Card balances accessible through
        your FINS Car Wash account to a physical FINS Car Wash gift card that
        you may use to make future purchases or gift to a designee of your
        choosing. FINS Car Wash reserves the right to terminate FINS Car Wash
        Offers/rewards Program, or any or all benefits under FINS Car Wash
        Offers/rewards, at any time, for any reason. In the event FINS Car Wash
        elects to terminate FINS Car Wash Offers/rewards, FINS Car Wash will
        provide a notice of termination in accordance with these Terms, as they
        may be modified or amended from time to time. In the event of such
        termination, at FINS Car Wash’s election, FINS Car Wash may allow you to
        redeem any outstanding valid and unexpired Offers/rewards at a
        participating FINS Car Wash until expiration of such Offers/rewards. ANY
        UNREDEEMED AND UNEXPIRED OFFERS/REWARDS SHALL BE CANCELLED AT THE LATER
        OF (1) THE EXPIRATION OF THE THEN-CURRENT OFFERS/REWARDS OR (2) NINETY
        (90) DAYS AFTER FINS CAR WASH OFFERS/REWARDS PROGRAM TERMINATION.
        <br />
        <br />
        <strong>General Restrictions.</strong> FINS Car Wash shall be the sole
        arbiter in cases of suspected abuse, fraud, or violation of its
        Offers/rewards Terms and Conditions and any decision it makes relating
        to termination or disabling of FINS Car Wash your FINS Car Wash account
        or of the overall FINS Car Wash Offers/rewards Program (including, but
        not limited to, cancellation of specific Offers/rewards) shall be final
        and binding. Should you object to any of these Offers/rewards Terms and
        Conditions, or any subsequent modifications thereto, or become
        dissatisfied with FINS Car Wash Offers/rewards, your sole remedy is to
        discontinue participation in FINS Car Wash Offers/rewards programs and
        properly cancel your account in the manner provided above.
        <br />
        <br />
      </p>

      <p>
        <strong>Copyright Infringement Notification</strong>
        The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
        recourse for copyright owners who believe that material appearing on the
        Internet infringes their rights under U.S. Copyright Law. If you believe
        in good faith that Materials available on the FINS Car Wash Mobile
        Application infringe your copyright, you (or your agent) should send us
        a notice requesting that we remove the Material or block access to it.
        If you believe in good faith that some has wrongly submitted a notice to
        us under this paragraph, then you should send us a counter-notice.
        Notices and counter-notices must meet the then-current statutory
        requirements imposed by the DMCA. See http://www.copyright.gov/ for
        details.
        <br />
        <br />
        If you believe that your work has been improperly copied and posted on
        the FINS Car Wash Mobile Application, then please provide us with the
        following information: (1) name, address, telephone number, email
        address, and an electronic or physical signature of the copyright owner
        or of the person authorized to act on his or her behalf; (2) a
        description of the copyrighted work that you claim has been infringed;
        (3) the applicable FINS Car Wash and a description of where the Material
        that you claim is infringing is located within such FINS Car Wash; (4) a
        written statement that you have a good faith belief that the disputed
        use is not authorized by the copyright owner, its agent, or the law; and
        (5) a statement by you, made under penalty of perjury, that the above
        information in your notice is accurate and that you are the copyright
        owner or authorized to act on the copyright owner’s behalf. These
        requirements must be followed to give us legally sufficient notice of
        infringement.
        <br />
        <br />
        Notices and counter-notices should be sent to:
        <br />
        <br />
        FINS Car Wash
        <br />
        ATTN: Marketing <br />
        info@finswash.com <br />
        3426 Toringdon Way, Suite 300
        <br />
        Charlotte, NC 28277
        <br />
        704-569-3600
        <br />
        <br />
        We suggest that you consult your legal advisor before filing a notice or
        counter-notice. Please be aware that there may be penalties for false
        claims under the DMCA. <br />
        <br />
      </p>
      <p>
        <strong>Login and Registration</strong>
        <br />
        <br />
        Certain programs, offers/rewards, features, or services offered on or
        through the FINS Car Wash Mobile Application may require you to complete
        a registration process and/or setup an account or login. In connection
        therewith, you agree to (1) provide true, accurate, current, and
        complete information about yourself, and (2) maintain and promptly
        update your information to keep it true, accurate, current, and
        complete. You are entirely responsible for maintaining the
        confidentiality of the information you hold for your login, including
        your password. You agree to notify FINS Car Wash immediately of any
        unauthorized use of your login, or any other breach of security. You may
        be held liable for losses incurred by FINS Car Wash user or visitor due
        to some else using your login as a result of your failing to keep your
        login information secure and confidential. <br />
        <br />
        You may not use any else’s login at any time without their express
        written permission. FINS Car Wash shall not be liable for any loss or
        damage arising from your failure to comply with these obligations.
      </p>

      <p>
        <strong> Mobile Apps</strong>
        <br />
        <br />
        In order to use FINS Car Wash Mobile Application made available through
        a mobile application (“Mobile App”) you must have a compatible mobile
        device; FINS Car Wash does not warrant that the Mobile App will be
        compatible with your mobile device.
        <br />
        <br />
        If you access the Mobile App using an Apple iOS or Android powered
        device, Apple Inc. or Google, Inc., and their subsidiaries,
        respectively, shall be a third-party beneficiary to this contract and
        you agree that these third-party beneficiaries have the right to enforce
        these Terms of Use against you. However, these third-party beneficiaries
        are not a party to these Terms and Conditions of Use and are not
        responsible for the provision or support of the Mobile App. You agree
        that your access to the Mobile App also shall be subject to the usage
        terms set forth in the applicable third-party beneficiary’s terms of
        service.
        <br />
        <br />
        The Mobile App may automatically download and install updates from time
        to time or require the downloading and installation of updates or new
        versions for continued use. You acknowledge that in some instances
        updates and/or new versions may reduce or remove features and
        functionality in prior versions of the Mobile App.
      </p>
      <p>
        <strong>Links To Third Party Sites and Social Media Pages</strong>
        <br />
        <br />
        The FINS Car Wash Mobile Application may contain links to other
        third-party websites, including the franchised operators of FINS Car
        Wash businesses (“Operators”), and social media platforms, such as
        Facebook, Twitter, or Foursquare (all links to third-party websites and
        social medial platforms are collectively referred to herein as “Linked
        Sites”). Linked Sites are provided only for your convenience. If you
        decide to visit any Linked Site, you do so at your own risk. FINS Car
        Wash and its Affiliates have no responsibility or liability for any
        Linked Sites, or the content, policies, or actions thereof. If you
        choose to purchase any product or service from a Linked Site (including,
        without limitation, from an Operator Linked Site), your relationship is
        with that third party. You agree that we are not responsible for the
        quality of third-party products or services, or fulfilling any of the
        terms of your agreement with the seller, including, but not limited to,
        the delivery of products and services and warranty obligations related
        to products and services. Links do not imply that we sponsor, endorse,
        are affiliated with or associated with, or are legally authorized to use
        any trademark, trade name, service mark, design, logo, symbol, or other
        copyrighted materials displayed on or accessible through such Linked
        Site.
      </p>
      <p>
        <strong>Privacy</strong>
        <br />
        <br />
        FINS Car Wash’s Privacy Policy applies to use of the FINS Car Wash
        Mobile Application and programs, offers/rewards, and promotions, and its
        terms are made a part of these Terms and Conditions of Use by this
        reference. By accessing and using the FINS Car Wash Mobile Application
        you agree to be bound by our Privacy Policy.
      </p>
      <p>
        <strong>
          Trademark Information and Ownership of Intellectual Property
        </strong>
        <br />
        <br />
        FINS Car Wash® is a registered trademark and service mark of AAA of the
        Carolina in the United States. The absence of a trademark notice or
        legend indicating the registration or ownership by FINS Car Wash does
        not constitute a waiver of trademark or other intellectual property
        rights concerning that trademark or service mark used or referenced on
        the FINS Car Wash Mobile Application. <br />
        <br />
        The FINS Car Wash Mobile Application, the Materials, and the trademarks,
        logos, service marks, and all other source identifying indicia displayed
        on the FINS Car Wash Mobile Application (collectively, the “FINS Car
        Wash Intellectual Property”) are owned by or licensed to FINS Car Wash,
        or others with all rights reserved unless otherwise noted. We do not
        permit third parties to use FINS Car Wash Intellectual Property in any
        manner, including advertising, as an endorsement for any product or
        service, in association with contests or promotions, or for any other
        purpose, commercial or otherwise, without our prior express written
        permission. <br />
        <br />
        Nothing contained on the FINS Car Wash Mobile Application should be
        construed as granting, by implication, estoppel, or otherwise, any
        license or right to use FINS Car Wash Intellectual Property without our
        prior express written permission. You are strictly prohibited from using
        any FINS Car Wash Intellectual Property except as expressly provided in
        these Terms and Conditions of Use. You are also advised that FINS Car
        Wash or AAA Carolinas will aggressively enforce its intellectual
        property rights to the fullest extent of the law, including seeking
        criminal prosecution.
      </p>
      <p>
        <strong>Electronic Communications</strong>
        <br />
        <br />
        When you visit the FINS Car Wash Mobile Application or send e-mails to
        us, you are communicating with us electronically. You consent to receive
        communications from us electronically. We will communicate with you by
        e-mail or by posting notices on the FINS Car Wash Mobile Application.
        You agree that all agreements, notices, disclosures, and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing. You further agree
        that any notices provided by us electronically are deemed to be given
        and received on the date we transmit any such electronic communication
        as described in these Terms and Conditions of Use.
      </p>
      <p>
        <strong>No Warranties</strong>
        <br />
        <br />
        FINS Car Wash makes no representations or warranties regarding the FINS
        Car Wash Mobile Application and the Materials.
        <br />
        <br />
        WE DO NO WARRANT THAT THE FINS CAR WASH MOBILE APPLICATION OR THE
        MATERIALS WILL BE ERROR-FREE, UNINTERRUPTED, OR FREE OF VIRUSES OR OTHER
        HARMFUL COMPNTS, OR THAT ANY DEFECTS WILL BE CORRECTED.
        <br />
        <br />
        WE MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING THE USE OF THE FINS
        CAR WASH MOBILE APPLICATION OR THE MATERIALS, PROGRAMS, OFFERS/REWARDS,
        FEATURES, AND SERVICES MADE AVAILABLE THROUGH THE FINS CAR WASH MOBILE
        APPLICATION, OR THE RESULTS OBTAINED THEREFROM, INCLUDING, WITHOUT
        LIMITATION, AS TO ACCURACY, TIMELINESS, RELIABILITY, OR OTHERWISE.
        <br />
        <br />
        THE FINS CAR WASH MOBILE APPLICATION AND THE MATERIALS, PROGRAMS,
        OFFERS/REWARDS, FEATURES, AND SERVICES MADE AVAILABLE THROUGH THE FINS
        CAR WASH MOBILE APPLICATION ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, EXPRESS OR
        IMPLIED, OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, WE HEREBY
        DISCLAIM ALL WARRANTIES OF ANY KIND OR NATURE, INCLUDING, BUT NOT
        LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, ACCURACY,
        NON-INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE. WE DISCLAIM ANY
        AND ALL LIABILITY FOR THE ACTS, OMISSIONS, AND CONDUCT OF ANY THIRD
        PARTY SERVICE PROVIDERS, PARTNERS, SPONSORS, LICENSORS, LICENSEES, OR
        THE LIKE (“PROVIDERS”) IN CONNECTION WITH OR RELATED TO YOUR USE OF THE
        FINS CAR WASH MOBILE APPLICATION AND THE MATERIALS, PROGRAMS,
        OFFERS/REWARDS, FEATURES, AND SERVICES MADE AVAILABLE THROUGH THE FINS
        CAR WASH MOBILE APPLICATION.
      </p>
      <p>
        <strong>Limitations of Liability</strong>
        <br />
        <br />
        IN NO EVENT SHALL WE OR ANY OF OUR PROVIDERS BE LIABLE TO ANY PERSON FOR
        DAMAGES OF ANY KIND, UNDER ANYLEGAL THEORY, INCLUDING, BUT NOT LIMITED
        TO, ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR OTHER
        DAMAGES (LOST PROFITS, BUSINESS INTERRUPTION, OR LOSS OF INFORMATION,
        PROGRAMS, OR DATA) RESULTING FROM YOUR USE OF OR INABILITY TO USE THE
        FINS CAR WASH MOBILE APPLICATION AND THE MATERIALS, PROGRAMS,
        OFFERS/REWARDS, FEATURES, AND SERVICES MADE AVAILABLE THROUGH THE FINS
        CAR WASH MOBILE APPLICATION, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES DO NOT PERMIT THE
        EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, IN SUCH JURISDICTIONS,
        LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW.
      </p>
      <p>
        <strong>Indemnification</strong>
        <br />
        <br />
        You agree to indemnify, defend, and hold harmless FINS Car Wash, its
        Affiliates, its Providers, and its and their officers, directors,
        employees, parent companies and agents from and against any and all
        claims, damages, losses, costs (including reasonable attorneys’ fees),
        and expenses that arise directly or indirectly out of or from: (1) your
        breach of these Terms and Conditions of Use; and/or (2) your activities
        in connection with the FINS Car Wash Mobile Application, and the
        Materials, programs, offers/rewards, features, and services made
        available through the FINS Car Wash Mobile Application.
      </p>
      <p>
        <strong>International Users and Choice of Law</strong>
        <br />
        <br />
        The FINS Car Wash Mobile Application are controlled, operated, and
        administered by us and our service providers from offices within the
        United States of America. We make no representation that Materials on
        the FINS Car Wash Mobile Application are appropriate or available for
        use at other locations outside of the United States, and access to them
        from territories where their contents are illegal is prohibited. You may
        not use the FINS Car Wash Mobile Application or export the Materials in
        violation of U.S. export laws and regulations. If you access the FINS
        Car Wash Mobile Application from locations outside of the United States
        of America, you are responsible for compliance with all local laws.
        These Terms and Conditions of Use and our Privacy Policy shall be
        governed by the laws of the State of North Carolina, U.S.A., without
        regard to conflict of laws provisions or principles.
      </p>
      <p>
        <strong>No Agency Relationship</strong>
        <br />
        <br />
        Neither these Terms and Conditions of Use, nor any Materials, programs,
        offers/rewards, features, or services of the FINS Car Wash Mobile
        Application, create any partnership, joint venture, employment, or other
        agency relationship between FINS Car Wash, its Affiliates, and you. You
        may not enter into any contract on our behalf or bind us in any way.
      </p>
      <p>
        <strong>
          Violation of these Terms and Conditions of Use and Remedies
        </strong>
        <br />
        <br />
        You agree that FINS Car Wash may, in its sole discretion and without
        prior notice, block and/or terminate your access to any or all of the
        FINS Car Wash Mobile Application if we determine that you have violated
        these Terms and Conditions of Use or other agreements or guidelines that
        may be associated therewith. You also agree that any violation, or
        threatened violation, by you of these Terms and Conditions of Use
        constitutes an unlawful and unfair business practice, and will cause
        irreparable and unquantifiable harm to FINS Car Wash. You also agree
        that monetary damages would be inadequate for such harm and consent to
        our obtaining any injunctive or equitable relief that we deem necessary
        or appropriate. These remedies are in addition to any other remedies we
        may have at law or in equity. <br />
        <br />
        If FINS Car Wash takes legal action against you as a result of your
        violation of these Terms and Conditions of Use, we will be entitled to
        recover from you, and you agree to pay, all reasonable attorneys’ fees
        and costs of such action, in addition to any other relief granted to
        FINS Car Wash. You agree that FINS Car Wash will not be liable to you or
        to any third party for termination of your access to the FINS Car Wash
        Mobile Application as a result of any violation of these Terms and
        Conditions of Use.
      </p>
      <p>
        <strong>Entire Agreement, Claims Limitations, and Severability</strong>
        <br />
        <br />
        These Terms and Conditions of Use, together with our Privacy Policy and
        any other applicable terms included within the FINS Car Wash Mobile
        Application, and/or the Materials, programs, offers/rewards, features,
        and services made available through the FINS Car Wash Mobile
        Application, as each is currently posted on the FINS Car Wash Mobile
        Application, constitute the entire agreement between us and you with
        respect to your use of the FINS Car Wash Mobile Application and the
        Materials, and supersede all previous written or oral agreements between
        us and you with respect to such subject matter. Any cause of action you
        may have arising out of or relating in any way to the FINS Car Wash
        Mobile Application must be commenced within (1) year after the claim or
        cause of action arises. If, for any reason, a court of competent
        jurisdiction finally determines any provision of these Terms and
        Conditions of Use, our Privacy Policy, or any portion thereof to be
        unenforceable, such provision shall be enforced to the maximum extent
        permissible so as to give the intended effect thereof, and the remainder
        of these Terms and Conditions of Use and Privacy Policy shall continue
        in full force and effect.
      </p>
      <p>
        <strong>Contact Information</strong>
        <br />
        <br />
        If you have any questions about FINS Car Wash Mobile Application or
        these Terms and Conditions of Use, or to report violations of these
        Terms and Conditions of Use, please contact us at:
        <br />
        <br />
        FINS Car Wash
        <br />
        3426 Toringdon Way, Suite 300
        <br />
        Charlotte, NC 28277
        <br />
        704-569-3600
      </p>
      <p>
        <strong>Last Updated</strong>
        <br />
        <br />
        Terms and Conditions of Use last updated on October 5, 2022.
        <br />
        Copyright © 2022 Fins Car Wash. All rights reserved.
      </p>
    </div>
  );
};

export default TermsPage;
